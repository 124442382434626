import React from 'react'
import Swiper from 'swiper'
import "swiper/css";
import "swiper/css/pagination";

// function Testimonial() {

//     document.addEventListener('DOMContentLoaded', function () {
//         const swiper = new Swiper('.swiper-container', {
//           loop: true,
//           slidesPerView: 1,
//           spaceBetween: 32,
//           autoplay: {
//             delay: 1000,
//           },
//           navigation: {
//             nextEl: '.next-button',
//             prevEl: '.prev-button',
//           },
//           breakpoints: {
//             640: {
//               slidesPerView: 1.5,
//               centeredSlides: true,
//             },
//             1024: {
//               centeredSlides: false,
//               slidesPerView: 2.25,
//             },
//           },
//         })
//       })

//   return (
//     <div><section className="bg-gray-100">
//     <div
//       className="mx-auto max-w-[1340px] px-4 py-16 sm:px-6 sm:py-24 lg:me-0 lg:pe-0 lg:ps-8"
//     >
//       <div className="max-w-7xl items-end justify-between sm:flex sm:pe-6 lg:pe-8">
//         <h2 className="max-w-xl text-4xl font-bold tracking-tight sm:text-5xl">
//           Read trusted reviews from our customers
//         </h2>

//         <div className="mt-8 flex gap-4 lg:mt-0">
//           <button
//             className="prev-button rounded-full border border-pink-600 p-3 text-pink-600 hover:bg-pink-600 hover:text-white"
//           >
//             <span className="sr-only">Previous Slide</span>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               strokeWidth="1.5"
//               stroke="currentColor"
//               className="h-5 w-5 rtl:rotate-180"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 d="M15.75 19.5L8.25 12l7.5-7.5"
//               />
//             </svg>
//           </button>

//           <button
//             className="next-button rounded-full border border-pink-600 p-3 text-pink-600 hover:bg-pink-600 hover:text-white"
//           >
//             <span className="sr-only">Next Slide</span>
//             <svg
//               className="h-5 w-5 rtl:rotate-180"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M9 5l7 7-7 7"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//               />
//             </svg>
//           </button>
//         </div>
//       </div>

//       <div className="-mx-6 mt-8 lg:col-span-2 lg:mx-0">
//         <div className="swiper-container !overflow-hidden">
//           <div className="swiper-wrapper">
//             <div className="swiper-slide">
//               <blockquote
//                 className="flex h-full flex-col justify-between bg-white p-12"
//               >
//                 <div>
//                   <div className="flex gap-0.5 text-white">
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                   </div>

//                   <div className="mt-4">
//                     <h3 className="text-2xl font-bold text-pink-600 sm:text-3xl">
//                       Lorem ipsum dolor sit amet.
//                     </h3>

//                     <p className="mt-4 text-lg text-gray-600">
//                       Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                       Ipsam cumque recusandae dolorum porro, quasi sunt
//                       necessitatibus dolorem ab laudantium vel.
//                     </p>
//                   </div>
//                 </div>

//                 <footer className="mt-8 text-gray-500">- Eddie Murphy</footer>
//               </blockquote>
//             </div>

//             <div className="swiper-slide">
//               <blockquote
//                 className="flex h-full flex-col justify-between bg-white p-12"
//               >
//                 <div>
//                   <div className="flex gap-0.5 text-white">
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                   </div>

//                   <div className="mt-4">
//                     <h3 className="text-2xl font-bold text-pink-600 sm:text-3xl">
//                       Lorem ipsum dolor sit amet.
//                     </h3>

//                     <p className="mt-4 text-lg text-gray-600">
//                       Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                       Ipsam cumque recusandae dolorum porro, quasi sunt
//                       necessitatibus dolorem ab laudantium vel.
//                     </p>
//                   </div>
//                 </div>

//                 <footer className="mt-8 text-gray-500">- Eddie Murphy</footer>
//               </blockquote>
//             </div>

//             <div className="swiper-slide">
//               <blockquote
//                 className="flex h-full flex-col justify-between bg-white p-12"
//               >
//                 <div>
//                   <div className="flex gap-0.5 text-white">
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                   </div>

//                   <div className="mt-4">
//                     <h3 className="text-2xl font-bold text-pink-600 sm:text-3xl">
//                       Lorem ipsum dolor sit amet.
//                     </h3>

//                     <p className="mt-4 text-lg text-gray-600">
//                       Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                       Ipsam cumque recusandae dolorum porro, quasi sunt
//                       necessitatibus dolorem ab laudantium vel.
//                     </p>
//                   </div>
//                 </div>

//                 <footer className="mt-8 text-gray-500">- Eddie Murphy</footer>
//               </blockquote>
//             </div>

//             <div className="swiper-slide">
//               <blockquote
//                 className="flex h-full flex-col justify-between bg-white p-12"
//               >
//                 <div>
//                   <div className="flex gap-0.5 text-white">
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                     <span className="bg-green-500 p-0.5">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-5 w-5"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                       >
//                         <path
//                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
//                         />
//                       </svg>
//                     </span>
//                   </div>

//                   <div className="mt-4">
//                     <h3 className="text-2xl font-bold text-pink-600 sm:text-3xl">
//                       Lorem ipsum dolor sit amet.
//                     </h3>

//                     <p className="mt-4 text-lg text-gray-600">
//                       Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                       Ipsam cumque recusandae dolorum porro, quasi sunt
//                       necessitatibus dolorem ab laudantium vel.
//                     </p>
//                   </div>
//                 </div>

//                 <footer className="mt-8 text-gray-500">- Eddie Murphy</footer>
//               </blockquote>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>

//   <script>

//   </script></div>
//   )
// }

// export default Testimonial


// import React from 'react'

function Testimonial() {
  return (
    <div className='bg-gray-100' >
     <div>
          <h1 className='text-center font-medium font-serif xs:text-2xl md:text-4xl xs:pt-4 md:pt-10'> What Our Costumer Says</h1>
          <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
          {/* <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4> */}
        </div>
    
    <div className='grid xs:grid-cols-1 grid-cols-3 mx-auto xs:pt-10 lg:pt-20 '>
      <div class="max-w-md mx-auto py-4 px-8 bg-white shadow-lg rounded-lg xs:mt-14 lg:my-20">
        <div class="flex justify-center md:justify-end -mt-16">
          <img class="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" />
        </div>
        <div>
          <h2 class="text-gray-800 text-3xl font-semibold"> Anish Agrawal</h2>
          <p class="mt-2 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!</p>
        </div>
        <div class="flex justify-end mt-4">
          {/* <a href="#" class="text-xl font-medium text-indigo-500">John Doe</a> */}
        </div>
      </div>
      <div class="max-w-md mx-auto py-4 px-8 bg-white shadow-lg rounded-lg xs:mt-20 lg:my-20">
        <div class="flex justify-center md:justify-end -mt-16">
          <img class="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" />
        </div>
        <div>
          <h2 class="text-gray-800 text-3xl font-semibold">Rakesh Jain</h2>
          <p class="mt-2 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!</p>
        </div>
        <div class="flex justify-end mt-4">
          {/* <a href="#" class="text-xl font-medium text-indigo-500">John Doe</a> */}
        </div>
      </div>
      <div class="max-w-md mx-auto py-4 px-8 bg-white shadow-lg rounded-lg xs:mt-20 my-20">
        <div class="flex justify-center md:justify-end -mt-16">
          <img class="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" />
        </div>
        <div>
          <h2 class="text-gray-800 text-3xl font-semibold">Anand Kasyap</h2>
          <p class="mt-2 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!</p>
        </div>
        <div class="flex justify-end mt-4">
          {/* <a href="#" class="text-xl font-medium text-indigo-500">John Doe</a> */}
        </div>
      </div>
    </div></div>
  )
}

export default Testimonial