import React from 'react'

function Quality() {
    return (
        <div>
            <div className=''>
                <img className='' src='newimage/process/quality.svg' alt=''></img>
            </div>
            <div>
                <h1 className=' pt-4 border-b-4 border-red-600 pb-4 xs:mx-auto w-fit text-2xl font-medium' style={{ fontFamily: "Roboto" }}> Quality Policy</h1>
                <h2 className='font-Roboto xs:text-center pt-6 text-[16px] font-medium'> At M/s Brothers Industries, we strongly believe,
                    Quality to Customer means conformance to the requirements
                    by way of product performance, cost, delivery & service.</h2>
            </div>
            <div>
                <h1 className=' pt-4 border-b-4 border-red-600 pb-4 w-fit xs:mx-auto text-2xl font-medium' style={{ fontFamily: "Roboto" }}> Key Factors</h1>
                <ul className='list-disc pl-6'>
                  
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Special Purpose Machines and Wide range of Calibrated Instruments and Gauges</li>
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Air Conditioned Inspection Room.</li>
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Expertise Quality personnel with NDT LEVEL II.</li>
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Quality at all stages.</li>
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Material Stock with Quality Approval.</li>
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Material Test Report and Lab Test Reports for material Conformance.</li>
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Different Welding Tests like, NDT, DPT etc.</li>
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Final Inspection Report.</li>
                    <li  className='font-Roboto pt-3 text-[16px] font-medium'>Special instruments like, Digital Height Gauge, Digital Hardness Tester etc.</li>
                </ul>
            </div>
        </div>
    )
}

export default Quality