import React, { useState } from 'react';
import ProductCard from './ProductCard';

const ProductPage = ({ products }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredProducts = selectedCategory === 'All'
    ? products
    : products.filter(product => product.category === selectedCategory);

  return (
    <div className="product-list">
      <div className="filter py-4 md:w-[360px] border-y-2 px-8 my-10" >
        {/* <label htmlFor="category" className=''>Filter by Category:</label> */}
        <select
          id="category"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option className='' value="All">All Category</option>
          <option value="Finish Machine Casting">Finish Machine Casting</option>
          <option value="Finish Machine Rounded Bar Parts">Finish Machine Rounded Bar Parts</option>
          <option value="Casting Metallurgy">Casting Metallurgy</option>
          <option value="Round Bar Metallurgy">Round Bar Metallurgy</option>
          {/* Add more category options as needed */}
        </select>
      </div>
      <div className="product-cards xs:grid-cols-2 xs:gap-2 grid grid-cols-4 gap-10">
        {filteredProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
