import React from 'react'

function Cards(props) {

    const {image , title , subTitle1 , subTitle2} = props

    return (
        <div><div class=" lg:mx-6 lg:p-10   antialiased text-gray-900">
            <div>

                <img src={image} alt={title}/>

                <div class="relative px-4 -mt-16  ">
                    <div class="bg-white p-6 rounded-lg shadow-lg">
                        <div class="flex items-baseline">
                            <span class="bg-teal-200 text-teal-800 text-xs px-2 inline-block rounded-full  uppercase font-semibold tracking-wide">
                                New
                            </span>
                            {/* <div class="ml-2 text-gray-600 uppercase text-xs font-semibold tracking-wider">
                                2 baths  &bull; 3 rooms
                            </div> */}
                        </div>

                        <h4 class="mt-1 text-xl font-semibold uppercase leading-tight truncate">{title}</h4>

                        <div class="mt-1">
                       {subTitle1}
                        {subTitle2}
                            {/* <span class="text-gray-600 text-sm">   /wk</span> */}
                        </div>
                        <div class="mt-4">
                            <span class="text-teal-600 text-md font-semibold">See More</span>
                            {/* <span class="text-sm text-gray-600">(based on 234 ratings)</span> */}
                        </div>
                    </div>
                </div>

            </div>
        </div></div>
    )
}

export default Cards