import React from 'react'

function Painting() {
    return (
        <div> <div className=''>
            <img className='' src='newimage/process/painting.svg' alt=''></img>
        </div>
            <div>
                <h1 className=' pt-4 border-b-4 border-red-600 pb-4 w-fit text-2xl font-medium xs:mx-auto' style={{ fontFamily: "Roboto" }}> Painting & Packaging</h1>
                <ul className='list-disc pl-6'>

                    <li className='font-Roboto pt-3 text-[16px] font-medium'>Dedicated Facility For Painting & Packaging.</li>
                    <li className='font-Roboto pt-3 text-[16px] font-medium'>components are painted or coated with a
                        protective layer to enhance their durability,
                        aesthetic appeal, and corrosion resistance</li>
                    <li className='font-Roboto pt-3 text-[16px] font-medium'>We can provide export-worthy packaging according to
                        required international standards. The packaging can be
                        designed to protect the products during transportation.</li>

                </ul>
            </div>
        </div>
    )
}

export default Painting