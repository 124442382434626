import React from 'react'
import './contact.css'
import { useEffect } from 'react'



function Contact() {

    useEffect(() => {
        const ifameData = document.getElementById("iframeId")
        const lat = 17.099715854771308;
        const lon = 74.451471812289;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
    })


    return (
        <>
            <div>
                <img className='w-full' alt='' src='newimage/banner/contact.svg'></img>
            </div>
            <div className='grid xs:grid-cols-1 xs:mx-4  mt-28 md:mx-28 gap-8 mb-10 grid-cols-3'>
                <div className=' col-span-1'>
                    <img className='xs:w-full' src='newimage/location.jpg' alt=''></img>
                    <h2 className='text-xl  py-5 font-Roboto font-bold border-b-2'>UNIT-1 (Machine Shop)</h2>
                    <div className='mt-4 ' >
                        <img></img>
                        <p className='text-[17px] hover:text-black cursor-pointer text-gray-600'>Dr PK Industrial Estate, Tal:- Palus, Dist:- Sangli, Maharastra, Pin:- 416310</p>
                    </div>
                    <p className='font-semibold w-[100px]  hover:text-red-400 cursor-pointer text-[14px] border-b-2 py-4' id="animated-border">Get Direction</p>
                </div>
                <div className=' col-span-1'>
                    <img src='newimage/location.jpg'></img>
                    <h2 className='text-xl  py-5 font-Roboto font-bold border-b-2'>UNIT-2 (Machine Shop)</h2>
                    <div className='mt-4 ' >
                        <img></img>
                        <p className='text-[17px] hover:text-black cursor-pointer text-gray-600'>Savantpur Road, Kirloskarwadi. Tal:- Palus, Dist:- Sangli, Maharastra, Pin:- 416310</p>
                    </div>
                    <p className='font-semibold w-[100px]  hover:text-red-400 cursor-pointer text-[14px] border-b-2 py-4' id="animated-border">Get Direction</p>
                </div>
                <div className=' col-span-1'>
                    <img src='newimage/location.jpg'></img>
                    <h2 className='text-xl  py-5 font-Roboto font-bold border-b-2'>UNIT-3 (CI Foundry)</h2>
                    <div className='mt-4 ' >
                        <img></img>
                        <p className='text-[17px] hover:text-black cursor-pointer text-gray-600'>B-34, MIDC, Gokul Shirgoan, Kolhapur - 416234.</p>
                    </div>
                    <p className='font-semibold w-[100px]  hover:text-red-400 cursor-pointer text-[14px] border-b-2 py-4' id="animated-border">Get Direction</p>
                </div>

            </div>

            <div className='grid  mb-10 xs:grid-cols-1 lg:grid-cols-2'>
                <div className='col-span-1'>
                    <div>
                        <h1 className=' xs:ml-10 font-bold lg:pl-10  xs:text-[40px] py-6 text-3xl font-Roboto '>Contact Info</h1>
                        <div className='grid xs:grid-cols-1 gap-4 mt-4 grid-cols-2 md:mx-10 xs:gap-10 xs:mx-4 '>
                            <div className='border   col-span-1  pt-6 pb-10'>
                                <img className='w-20  pb-8 mx-auto' src='newimage/phone.png'></img>
                                <h4 className='text-center text-xl font-Roboto font-semibold'>Call Us</h4>
                                <h5 className='text-center text-[16px] py-2 font-Roboto font-medium text-gray-600'>+91 9920139101</h5>
                                <h5 className='text-center text-[16px]  font-Roboto font-medium text-gray-600'>+91 7588777800</h5>
                            </div>
                            <div className='border   col-span-1  pt-6 pb-10'>
                                <img className='w-20  pb-8 mx-auto' src='newimage/envelope.png'></img>
                                <h4 className='text-center text-xl font-Roboto font-semibold'>Mail Us</h4>
                                <h5 className='text-center text-[16px] py-2 font-Roboto font-medium text-gray-600'>ceo@brothers.net.in</h5>
                                <h5 className='text-center text-[16px]  font-Roboto font-medium text-gray-600'>brothersindrustries07@gmail.com</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' md:ml-10 col-span-1'>

                    <h1 className=' xs:mx-auto  font-bold  xs:text-[40px] py-6 text-3xl font-Roboto '>Drop us Message</h1>
                    <form class="w-full  mt-4 max-w-xl">
                        <div class="flex  flex-wrap md:-mx-3 mb-6">
                            <div class="w-full xs:mb-2 md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Your Name
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter Name" />
                            </div>
                            <div class="w-full xs:mb-5 md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Your Email
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="email" placeholder="Enter Email" />
                            </div>
                            <div class="w-full xs:mb-2 md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Contact Number
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" placeholder="Enter Number" />

                            </div>
                            <div class="w-full xs:mb-5 md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    City
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="City Name" />
                            </div>

                        </div>
                        <div class="flex flex-wrap -mt-4 md:-mx-3 ">
                            <div class="w-full px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Massage
                                </label>
                                <textarea class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 mb-2  focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter Massage" />
                            </div>
                        </div>
                        <button className='  mt-4 h-10 bg-[#D9D9D9] text-black  text-lg font-bold hover:bg-red-800 hover:text-white border-2 w-full'>Send</button>
                    </form>
                </div>
            </div>
            <div className='mt-40 '>
                <iframe id="iframeId" height="500px" width="100%"></iframe>
            </div>
        </>
    )
}

export default Contact