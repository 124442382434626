import React, { useState } from 'react';

const ProductCard = ({ product }) => {
  const [showAddToCart, setShowAddToCart] = useState(false);

  const handleMouseEnter = () => {
    setShowAddToCart(true);
  };

  const handleMouseLeave = () => {
    setShowAddToCart(false);
  };

  const { image, title, description , index } = product;

  return (
    <div
    
      className="  "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className=" relative    w-full xs:h-[230px] md:h-[260px] md:rounded-t-sm cursor-pointer" key={index}  style={{ backgroundImage: `url(${image})` }}> 
        {/* <img src={image} alt={title} className="product-image" /> */}
        {showAddToCart && (
          <button className="inquiry  py-2 px-4 absolute inset-x-0 bottom-0 hover:bg-black  text-white w-full bg-red-600">Send inquiry</button>
        )}
      </div>
      
      <div className="product-details">
        <h2 className=" font-bold font-Roboto text-lg  text-center pt-3">{title}</h2>
        {/* <p className="product-category">{category}</p> */}
        <p className="product-description pt-2 text-center">{description}</p>
      </div>
    </div>
  );
};

export default ProductCard;
