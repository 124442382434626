import React, { useState } from 'react'
import 'typeface-roboto';



function FoundaryFacility() {
    const [expandedStates, setExpandedStates] = useState({});

    const cardData = [
        {
           
          title: "Pot Size",
          description: "1. 250 kg capacity ",
          descriptions: "2. 100 kg Capacity",
        //   seeMore:"xyz",
        
          imageUrl: "newimage/process/potsize.jpg",
        },
     
        {
          
            title: "Equipments",
            description: "Short description of card 2",
            imageUrl: "newimage/process/eqipment.jpg",
            seeMore:"xyz",
          },
          {
          
            title: "Testing",
            description: "Short description of card 2",
            imageUrl: "newimage/process/TESTING.jpg",
            seeMore:"xyz",
          },
          {
          
            title: "Capacity",
            description: "Single piece casting capacity – 200 k",
            descriptions: "Monthly capacity - 50 Ton ",
            imageUrl: "newimage/process/potsize.jpg",
            seeMore:"xyz",
          },
      ];


      const toggleExpanded = (cardid) => {
        setExpandedStates((prevState) => ({
          ...prevState,
          [cardid]: !prevState[cardid],
        }));
      };



    return (
        <div> <div className=''>
            <img className='' src='newimage/process/foundaryImage.svg' alt=''></img>
        </div>
            <div>
                <h1 className=' pt-4 border-b-4 border-red-600 pb-4 w-fit text-3xl font-medium' style={{ fontFamily: "Roboto" }}> Cast Iron Foundary - Brother Metal</h1>
                <h2 className='font-Roboto pt-6 text-xl font-medium'>INDUCTION FURNACE - <sapan>Indo power make induction furnace for assurance of quality casting</sapan> .</h2>
                <div className='grid xs:grid-cols-1 grid-cols-3 gap-10 mt-14  '>
                    {cardData.map((card , index)=>{

                        return (
                            <div className="  bg-white h-[360px] shadow-lg rounded-xl  " >
                       <div> <img    src={card.imageUrl} alt="Card Image" className="w-full z-0" /></div>
                        <div className="  rounded-xl pl-6 mt-4 bg-white  " >
                            <h3 className='font-Roboto font-bold text-xl  text-[#FF4E4E]'>{card.title}</h3>
                            <p className='py-1'>{card.description}</p>
                            <p className='py-1'>{card.descriptions}</p>
                            {expandedStates[index] && (
                                <div className="expanded-content">
                                    <p  >{card.seeMore}</p>
                                </div>
                            )}
                            <button className='text-yellow-600' onClick={()=>toggleExpanded(index)}>
                            {expandedStates[index] ? "See Less" : "See More"}
                            </button>
                        </div>
                    </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default FoundaryFacility