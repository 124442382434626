import React from 'react'
import 'typeface-roboto';
import FoundaryFacility from './FoundaryFacility';
import Quality from './Quality';
import { useState } from 'react';

const Machine = () => {
  return (
    <div>
      <div className=''>
        <img className='md:w-[95%] md:h-[500px]' src='newimage/products/DSC01155.jpg' alt=''></img>
      </div>


      <div className='grid xs:grid-cols-1 grid-cols-2 mt-20 gap-10'>
        <div className='flex'>
          <img className='w-[200px] h-[100px]' src='newimage/process/machineImage.svg' alt=''></img>
          <div>
            <div className='pl-4 '> <p className=' text-lg font-bold text-[#CB0000]'>Conventional Turning</p><p className='font-semibold'>12 NOS</p></div>
          </div>
        </div>

        <div className='flex'>
          <img className='w-[200px] h-[100px]' src='newimage/process/machineImage.svg' alt=''></img>
          <div>
            <div className='pl-4 '> <p className=' text-lg font-bold text-[#CB0000]'>Radial Drilling Machines </p><p className='font-semibold'>1 NOS</p></div>
          </div>
        </div>
        <div className='flex'>
          <img className='w-[200px] h-[100px]' src='newimage/process/machineImage.svg' alt=''></img>
          <div>
            <div className='pl-4 '> <p className=' text-lg font-bold text-[#CB0000]'> Welding Machines </p><p className='font-semibold'>3 NOS</p></div>
          </div>
        </div>
        <div className='flex'>
          <img className='w-[200px] h-[100px]' src='newimage/process/machineImage.svg' alt=''></img>
          <div>
            <div className='pl-4 '> <p className=' text-lg font-bold text-[#CB0000]'>Sand Blasting and Painting Booth</p><p className='font-semibold'>1 NOS</p></div>
          </div>
        </div>
        <div className='flex'>
          <img className='w-[200px] h-[100px]' src='newimage/process/machineImage.svg' alt=''></img>
          <div>
            <div className='pl-4 '> <p className=' text-lg font-bold text-[#CB0000]'> Bar Cutting Machine </p><p className='font-semibold'>1 NOS</p></div>
          </div>
        </div>
        <div className='flex'>
          <img className='w-[200px] h-[100px]' src='newimage/process/machineImage.svg' alt=''></img>
          <div>
            <div className='pl-4 '> <p className=' text-lg font-bold text-[#CB0000]'>Dot Pin Marker Machine</p><p className='font-semibold'>1 NOS</p></div>
          </div>
        </div>

      </div>

      <div className=''>
        <h1 className=' pt-4 border-b-4 border-red-600 pb-4 w-fit text-2xl font-medium' style={{ fontFamily: "Roboto" }}> Recenet Machine & Equipment</h1>
        <div className='mt-10 xs:grid-cols-1 gap-y-10 grid grid-cols-3'>
          <div className='w-80 border col-span-1'>
          <img className='mx-auto' src='newimage/process/CNC VTL.jpg'></img>
          <h1 className="text-center font-Roboto font-medium py-4">Brand New CNC VTL (IMPORTED)</h1>
          </div>
          <div className='w-80 border col-span-1'>
          <img className='mx-auto' src='newimage/process/TIGG.jpg'></img>
          <h1 className="text-center font-Roboto font-medium py-4">TIG Welding Facilit</h1>
          </div>
          <div className='w-80 border col-span-1'>
          <img className='mx-auto' src='newimage/process/DIGITAL Height Gauge.jpg'></img>
          <h1 className="text-center font-Roboto font-medium py-4">DIGITAL Height Gauge</h1>
          </div>
          <div className='w-80 border col-span-1'>
          <img className='mx-auto' src='newimage/process/Digital Hardness Tester.jpg'></img>
          <h1 className="text-center font-Roboto font-medium py-4">Digital Hardness Tester</h1>
          </div>
          <div className='w-80 border col-span-1'>
          <img className='mx-auto' src='newimage/process/CMM Machine.jpg'></img>
          <h1 className="text-center font-Roboto font-medium py-4">CMM Machine</h1>
          </div>
        </div>
      </div>

    </div>
  )
}


export default Machine