import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './BannerCarousel.css'


const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    
    
  };

const BannerCarusel = () => (
    
    <Slider {...settings}>
    <div className="" >
      <img className="w-full  " src="newimage/banner/banner3.svg" alt="" />
      
    </div>
    <div>
      <img className="w-full    " src="newimage/banner/banner2new.svg" alt="" />
    </div>
    <div className="" >
      <img className="w-full  " src="newimage/banner/banner1.svg" alt="" />
      
    </div>
    <div className="" >
      <img className="w-full  " src="newimage/banner/banner4.svg" alt="" />
      
    </div>
  </Slider>
);
export default BannerCarusel
