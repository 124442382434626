import React from 'react'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Nav from '../Navbar/Nav';
import "./header.css"
import { useEffect } from 'react';
import NavBar from '../NabBar/NavBar';
// import "./header.scss"

function Header() {

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (


    <div>
      <div className='bg-red-800 justify-between xs:hidden flex  w-full   	h-10'>
      
        <div className='ml-20 flex'>
          {/* <img className='w-7' src='newimage/fb.svg' alt='fb'></img>
          <img className='w-7' src='newimage/fb.svg' alt='fb'></img>
          <img className='w-7' src='newimage/fb.svg' alt='fb'></img> */}
          <ul className="flex gap-6 my-auto">
                                <li>
                                    <a
                                        href="/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-gray-200 transition hover:opacity-75"
                                    >
                                        <span className="sr-only">Facebook</span>

                                        <svg
                                            className="w-6 h-6"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-gray-200 transition hover:opacity-75"
                                    >
                                        <span className="sr-only">Instagram</span>

                                        <svg
                                            className="w-6 h-6"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-gray-200 transition hover:opacity-75"
                                    >
                                        <span className="sr-only">Twitter</span>

                                        <svg
                                            className="w-6 h-6"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                                            />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
          <span></span>

        </div>
        <div className='flex mr-6'>
        <img src='' alt=''></img>
          <span className='text-gray-200 text-[13px] my-auto mx-4 ' >ceo@brothers.net.in </span>
          <span className='text-gray-200 text-[13px] my-auto ' >brothersindrustries07@gmail.com</span>
          <img className='mr-4' src='' alt=''></img>
          <a       href={`tel:${9920139101}`} className='my-auto'><p className='text-gray-200 text-[13px] my-auto ' >+91 9920139101, </p></a>
          <span className='text-gray-200 text-[13px]  text-sm my-auto ml-3' >+91 9920139101 </span>
        </div>
      </div>
      <div className={`header xs:h-20   grid grid-cols-12 bg-gray-100 xs:shadow-2xl  w-full ${isScrolled ? 'header-scroll' : ''}`}>
        <img src="newimage/logo/logoBrother.png" className=' md:w-44 xs:mt-3 xs:ml-5  col-span-4  lg:ml-40 '></img>
        <div className='md:hidden'><Nav /></div>
        <div className='flex gap-16 pl-40  xs:hidden  col-span-8'>
          <NavLink to="/" className="font-semibold hover:border-b-4 hover:border-red-700 text-[16px] font-Roboto text-center py-5 text-slate-700 duration-200  " >Home</NavLink>
          <NavLink to="/about" className="font-semibold hover:border-b-4 hover:border-red-700   py-5  text-[16px] font-Roboto duration-200 ">About us</NavLink>
          <div className="dropdown z-10">
            <button className='font-Roboto font-semibold  py-5  text-[16px]  duration-700'>Services</button>
            <div className="dropdown-menu ">
              <ul>
                <li><a href='/service'>Machining Facilities</a></li>
                <li><a href='/service'>Foundry Facilities</a></li>
                <li> <a href='/service'>Quality Control</a></li>
                <li><a href='/service'> Painting & Packaging</a></li>
                <li> <a href='/service'>Logistics </a></li>

              </ul>
            </div>
          </div>
          <NavLink to="/products" className="font-semibold hover:border-b-4 hover:border-red-700  py-5   text-[16px] font-Roboto duration-200   ">Products</NavLink>
          <NavLink to="/gallery" className="font-semibold hover:border-b-4 hover:border-red-700  py-5   text-[16px] font-Roboto duration-200   ">Gallery</NavLink>

          <NavLink to="/contact" className="font-semibold py-5 hover:border-b-4 hover:border-red-700  text-[16px] font-Roboto duration-200   ">Contact us</NavLink>
          {/* <button onClick={downloadPDF} className=" font-bold  text-light-500 duration-700   ">Download Boucher</button> */}
        </div>
      </div>

    </div>

  )
}

export default Header