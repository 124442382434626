import React, { useState, useRef, useEffect } from 'react'
import "./home.css"
import "./home.scss"
import Partner from './Partner'
import Testimonial from './Testimonial'
import PauseOnHover from './CardSlider/CardSlider'
import BannerCarousel from '../BannerCarousel/BannerCarousel'


function Home() {


  return (

    <div className=''>
      <div >
        <BannerCarousel />
      </div>

      <div className='lg:mx-20 xs:mx-10 xs:my-6'>
        <div>
          <h1 className='text-center font-medium font-serif xs:text-2xl md:text-4xl xs:pt-4 md:pt-10'> Welcome to Brothers Indrusties</h1>
          <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
          <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4>
        </div>
        <div className='mt-8'> <PauseOnHover /></div>
      </div>

      <section className=''>
        <h1 className='text-center font-medium font-serif xs:text-xl md:text-4xl xs:pt-4 md:mt-20'> Why us</h1>
        <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
        <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4>
        <div className=' lg:flex mt-10 '>
          <div className='col-span-1 xs:w-full lg:w-[60%] ' >
            <div className="video-player ">
              <video controls src="newimage/video/5TECHg.mp4" />

            </div>
          </div>

          <div className='lg:w-[40%]'>
            <h2 className=' xs:text-center xs:pt-10 lg:pt-40 text-center text-4xl font-medium' style={{ fontFamily: "Roboto" }}>Focusing on strategic decisions & practical actions
            </h2>
          </div>
        </div>

      </section>

      <div className=''>
        <h1 className='text-center font-medium font-serif xs:text-xl md:text-4xl xs:pt-4 md:mt-20'> Our Services</h1>
        <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
        <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4>

        <div className=''>
          <div className='grid xs:grid-cols-1  xs:gap-4 xs:mx-3 md:grid-cols-2 xl:grid-cols-2 mt-10 lg:mx-8 lg:gap-y-4 '>

            <figure className="image-block col-span-1 md:mx-auto cursor-pointer  xs:w-40 xs:h-[470px] xs:min-w-fit md:w-[700px] md:h-[400px]  ">
              {/* <h1>FOUNDRY FACILITIES</h1> */}
              <img src="newimage/products/faundary.jpg" alt="" />
              <figcaption>
                <h3>
                  FOUNDRY FACILITIES
                </h3>
                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                <button>
                  More Info
                </button>
              </figcaption>
            </figure>
            <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit md:w-[700px] md:h-[400px]   ">
              {/* <h1>quality control</h1> */}
              <img className='h-full' src="newimage/products/quality control.png" alt="" />
              <figcaption>
                <h3>
                  QUALITY CONTROL
                </h3>
                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                <button>
                  More Info
                </button>
              </figcaption>
            </figure>
            <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit md:w-[700px] md:h-[400px]  ">
              {/* <h1>Product development</h1> */}
              <img src="newimage/products/Product development.jpg" alt="" />
              <figcaption>
                <h3>
                  PRODUCT DEVELOPMENT
                </h3>
                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                <button>
                  More Info
                </button>
              </figcaption>
            </figure>
            <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit  md:w-[700px] md:h-[400px]  ">
              {/* <h1>FOUNDRY FACILITIES</h1> */}
              <img src="newimage/products/DSC01155.jpg" alt="" />
              <figcaption>
                <h3>
                  MACHINING FACILITIES
                </h3>
                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                <button>
                  More Info
                </button>
              </figcaption>
            </figure>
            <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] md:w-[700px] md:h-[400px]  xs:min-w-fit  ">
              {/* <h1>quality control</h1> */}
              <img className='h-full' src="newimage/products/packaging.jpg" alt="" />
              <figcaption>
                <h3>
                  PAINTING AND PACKAGING
                </h3>
                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                <button>
                  More Info
                </button>
              </figcaption>
            </figure>
            <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit md:w-[700px] md:h-[400px] ">
              {/* <h1>Product development</h1> */}
              <img src="newimage/products/shiping.jpg" alt="" />
              <figcaption>
                <h3>
                  LOGISTICS
                </h3>
                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                <button>
                  More Info
                </button>
              </figcaption>
            </figure>

          </div>

        </div>
      </div>

      <div className='grid md:grid-cols-2 my-16 '>
        <div className='col-span-1'>
          <img src='newimage/globel-pracence.jpg' alt=''></img>
        </div>
        <div>
          <h2 className=' xs:text-center xs:pt-10 pt-[10%] text-center text-4xl font-medium' style={{ fontFamily: "Roboto" }}>Globel Pracence</h2>
          <p className='mt-6  text-lg mx-20'>
            Brother Industries has established a strong global presence, delivering excellence in every corner of the world."
            With offices and manufacturing facilities strategically located worldwide, Brother Industries ensures a seamless and efficient experience for our global clientele.
            With a commitment to serving diverse markets across continents, Brother Industries proudly extends its reach to provide unrivaled products and services on a global scale.</p>
        </div>
      </div>
      <div >
        <Partner />
      </div>
      <div >
        <Testimonial />
      </div>

    </div>
  )
}

export default Home