import React, { Component } from "react";
import Slider from "react-slick";
import Cards from "./Cards";

export default class PauseOnHover extends Component {
  render() {
    var settings = {
      
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,

      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      
    };
    return (
      <div>
        {/* <h2>Pause On Hover</h2> */}
        <Slider {...settings}>
          <div>
          <Cards image = {"newimage/products/DSC07551.jpg"}  title={ "Muff Coupling"} subTitle1={" (OD 225*ID 180*LG 480 MM)"} subTitle2={"(Centrifugal Casting, CF3M)" } />
          </div>
          <div>
          <Cards image = {"newimage/products/DSC07596.jpg"}  title={ "Bearing Cover"} subTitle1={" (MOC - A 48 CL 30)"} subTitle2={"" } />
          </div>
          <div>
          <Cards image = {"newimage/products/DSC07665.jpg"}  title={ "Shaft Sleeves"} subTitle1={" (MOC - EN8, 4140 SS304, 304)"} subTitle2={"(Duplex & Super Duplex)" } />
          </div>
          <div>
          <Cards image = {"newimage/products/DSC07551.jpg"}  title={ "Muff Coupling"} subTitle1={" (OD 225*ID 180*LG 480 MM)"} subTitle2={"(Centrifugal Casting, CF3M)" } />
          </div>
          <div>
          <Cards image = {"newimage/products/DSC07596.jpg"}  title={ "Bearing Cover"} subTitle1={" (MOC - A 48 CL 30)"} subTitle2={"" } />
          </div>
          <div>
          <Cards image = {"newimage/products/DSC07665.jpg"}  title={ "Shaft Sleeves"} subTitle1={" (MOC - EN8, 4140 SS304, 304)"} subTitle2={"(Duplex & Super Duplex)" } />
          </div>
        </Slider>
      </div>
    );
  }
}