import React from 'react'
import Home from '../Home/Home'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from '../About/About'
import Contact from '../Contact/Contact'
import MachiningFacility from '../Services/MachiningFacility'
import Service from '../Services/Service'
import ProductPage from '../Product/ProductPage'
import ProductCard from '../Product/ProductCard'
import ProductCategory from '../Product/ProductCategory'
import Gallery from '../Gallery/Gallery'


function Page() {
    return (
        <div><BrowserRouter>
            <Header />
            <Routes  >
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/service" element={<Service />} />
                <Route exact path="/products" element={<ProductCategory/>} />
                <Route exact path="/gallery" element={<Gallery />} />
            </Routes>
            {/* footer */}
            <Footer />
        </BrowserRouter></div>
    )
}

export default Page