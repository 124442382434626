import React from 'react'

function Logistics() {
    return (
        <div> <div className=''>
            <img className='' src='newimage/process/shipinG.svg' alt=''></img>
        </div>
            <div>
                <h1 className=' pt-4 border-b-4 border-red-600 pb-4 w-fit text-2xl font-medium xs:mx-auto' style={{ fontFamily: "Roboto" }}> Our Logisticss Key Feteurs</h1>
                <ul className='list-disc pl-6'>

                    <li className='font-Roboto pt-3 text-[16px] font-medium'> Delivery Terms Possible: EXW, FCA, FAS, FOB, CFR, CIF And DDP</li>
                    <li className='font-Roboto pt-3 text-[16px] font-medium'>We have established partnerships with several reputable
                        logistic companies to ensure timely and cost-effective
                        delivery of our products</li>
                    <li className='font-Roboto pt-3 text-[16px] font-medium'>We use reliable and reputable shipping companies that
                        have a proven track record of delivering goods
                        on time and in good condition</li>
                    <li className='font-Roboto pt-3 text-[16px] font-medium'>We ship our products throughout the globe</li>

                </ul>
            </div>
        </div>
    )
}

export default Logistics