import React from 'react';
import ProductPage from './ProductPage';

const products = [
  {
    id: 1,
    title: ' Casing ',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/casing.jpg",
  },
  {
    id: 2,
    title: ' Guide ',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/Guide.jpg",
  },
  {
    id: 3,
    title: ' Vane ',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/vane.jpg",
  },
  {
    id: 4,
    title: ' Seal Cover ',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/Seal-Cover.jpg",
  },
  {
    id: 5,
    title: ' Bearing Cover ',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/Bearing-Cover.jpg",

  },
  {
    id: 6,
    title: ' Casing Cove',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/Casing-Cove.jpg",

  },
  {
    id: 7,
    title: ' Rings',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/Rings.svg",

  },

  {
    id: 8,
    title: ' Interstage Casing ',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/Interstage-Casing.jpg",

  },

  {
    id: 9,
    title: ' Interstage ',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/Interstage.jpg",

  },

  {
    id: 10,
    title: ' Gland Cove ',
    category: 'Finish Machine Casting',
    description: 'used durable material',
    image:  "newimage/products/Gland-Cove.jpg",

  },
  {
    id: 11,
    title: 'Shaft Sleeve',
    category: 'Finish Machine Rounded Bar Parts',
    description: 'Product 2 description',
    image:  "newimage/products/Shaft-Sleeve.jpg",
  },
  {
    id: 12,
    title: 'Bush',
    category: 'Finish Machine Rounded Bar Parts',
    description: 'Product 2 description',
    image:  "newimage/products/bush.jpg",
  },
  {
    id: 13,
    title: 'Case and Impeller Wear Ring',
    category: 'Finish Machine Rounded Bar Parts',
    description: 'Product 2 description',
    image:  "newimage/products/casingRing.jpg",
  },
  {
    id: 26,
    title: 'Case and Impeller Wear Ring',
    category: 'Finish Machine Rounded Bar Parts',
    description: 'Product 2 description',
    image:  "newimage/products/impallerRing.jpg",
  },
  {
    id: 14,
    title: 'Impeller Nut',
    category: 'Finish Machine Rounded Bar Parts',
    description: 'Product 2 description',
    image:  "newimage/products/Impeller-Nut.jpg",
  },
  {
    id: 15,
    title: 'Seal Cove',
    category: 'Finish Machine Rounded Bar Parts',
    description: 'Product 2 description',
    image:  "newimage/products/sealCover.jpg",
  },
  {
    id: 16,
    title: 'Shaft Nut',
    category: 'Finish Machine Rounded Bar Parts',
    description: 'Product 2 description',
    image:  "newimage/products/safttNut.jpg",
  },
  {
    id: 17,
    title: 'Cast Iron (A48 CL30 ) and Ductile Iron',
    category: 'Casting Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/Cast-Iron.jpg",
  },
  {
    id: 18,
    title: 'Stainless Steel (A743 CF8, CF3, CF8M & CF3M',
    category: 'Casting Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/A743.jpg",
  },
  {
    id: 19,
    title: 'Steel (A216 Gr.WCB)',
    category: 'Casting Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/Steel.jpg",
  },
  {
    id: 20,
    title: 'BuSS 316/316sh',
    category: 'Round Bar Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/316sh.jpg",
  },
  {
    id: 21,
    title: 'SS304/304L 41',
    category: 'Round Bar Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/304L41.jpg",
  },
  {
    id: 22,
    title: '4140',
    category: 'Round Bar Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/4140.jpg",
  },
  {
    id: 23,
    title: '420',
    category: 'Round Bar Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/420.jpg",
  },
  {
    id: 24,
    title: 'Duplex',
    category: 'Round Bar Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/Duplex.jpg",
  },
  {
    id: 25,
    title: 'Super Duplex',
    category: 'Round Bar Metallurgy',
    description: 'Product 2 description',
    image:  "newimage/products/SuperDuplex.jpg",
  },

 
];

const ProductCategory = () => {
  return (
    <div className="mt-10 xs:mx-4 mx-40 mb-20 ">
      <h2 className=' font-bold text-3xl' style={{ fontFamily: "Roboto" }}>Product List</h2>
      <ProductPage products={products} />
    </div>
  );
};

export default ProductCategory;
