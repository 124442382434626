import React from 'react'
import 'typeface-roboto';
import FoundaryFacility from './FoundaryFacility';
import Quality from './Quality';
import { useState, useEffect } from 'react';
import Machine from './MachiningFacility';
import Painting from './Painting';
import Logistics from './Logistics';

function Service() {

  const [selectedCategory, setSelectedCategory] = useState("A");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);

  };


  return (
    <div className=''>
      <div>
        <img className='w-full' src='newimage/banner/services.png'></img>
      </div>
      <div className='grid xs:grid-cols-1  grid-cols-6 '>
        {/* <div className={`col-span-4 ${isScrolled ? ' col-span-4  ' : 'col-span-4'} `}> */}
        <div className=' xs:col-span-1 px-8 col-span-2'>
          <div className='border sticky top-24  pt-8  pb-52 xs:pb-10  mt-10  pl-10 bg-[#F7F7F7]'>
            <h2 className=' font-bold text-3xl' style={{ fontFamily: "Roboto" }}>Our Services</h2>
            <div className='border mt-6 border-white mr-10' >
              <ul className=''>
                <li className={selectedCategory === 'A' ? ' text-white bg-[#FF4E4E] pl-8 text-[16px] py-4 duration-300  delay-200' : ' bg-white cursor-pointer  py-4 pl-8 text-[16px]'} onClick={() => handleCategoryClick('A')}>  Machine Facility</li>
                <li className={selectedCategory === 'B' ? ' text-white bg-[#FF4E4E] pl-8 text-[16px] py-4 duration-300 delay-200 ' : ' bg-white  cursor-pointer py-4 pl-8 text-[16px]'} onClick={() => handleCategoryClick('B')}  > Foundary Facility </li>
                <li className={selectedCategory === 'C' ? ' text-white bg-[#FF4E4E] pl-8 text-[16px] py-4 duration-300 delay-200 ' : ' bg-white  cursor-pointer py-4 pl-8 text-[16px]'} onClick={() => handleCategoryClick('C')}  > Quality Control</li>
                <li className={selectedCategory === 'D' ? ' text-white bg-[#FF4E4E] pl-8 text-[16px] py-4 duration-300 delay-200 ' : ' bg-white  cursor-pointer py-4 pl-8 text-[16px]'} onClick={() => handleCategoryClick('D')}  > Painting & Packaging </li>
                <li className={selectedCategory === 'E' ? ' text-white bg-[#FF4E4E] pl-8 text-[16px] py-4 duration-300 delay-200 ' : ' bg-white  cursor-pointer py-4 pl-8 text-[16px]'} onClick={() => handleCategoryClick('E')}  > Logistics</li>
              </ul>
            </div>
          </div>
        </div>
        <div className=' xs:col-span-1 col-span-4 mb-20 '>
          <div className=" mt-10">
            {selectedCategory === 'A' && <Machine />}
            {selectedCategory === 'B' && <FoundaryFacility />}
            {selectedCategory === 'C' && <Quality />}
            {selectedCategory === 'D' && <Painting />}
            {selectedCategory === 'E' && <Logistics />}
            {/* {selectedCategory === 'C' && <ComponentC />} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service