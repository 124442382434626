import React from 'react'

function About() {
    return (

        <div>
            <div>
                <img className='w-full' src='newimage/banner/aboutBrother.svg'></img>
            </div>
            <div>

                <h1 className='text-center font-medium font-serif xs:text-xl md:text-4xl xs:pt-4 md:pt-10'> Welcome to Brothers Indrusties</h1>
                <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
                <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4>

                <div className=' pt-14 xs:grid-cols-1 grid md:grid-cols-2'>
                    <div className=' col-span-1'>
                        <img src="newimage/whyus.jpg" className='w-[720px]  mx-auto'></img>
                    </div>
                    <div className=' pl-4 col-span-1'>
                        <h1 className='  font-medium  xs:text-xl md:text-3xl xs:pt-4 md:pt-3'> Our Mission</h1>
                        <p className='font-serif pr-14 text-[16px] pt-4'>To deliver high quality machined and fabricated products and
                            assembly services, on time and at the least total cost to our customers.</p>
                        <h1 className=' font-medium xs:text-xl md:text-3xl xs:pt-4 md:pt-7'> Our Vission</h1>
                        <p className='font-serif  pr-14  text-[16px] pt-4'>To be the best-in className and preferred partner for the
                            ever changing needs of the precision manufacturing industry.</p>
                        <h1 className=' font-medium  xs:text-xl md:text-3xl xs:pt-4 md:pt-7'> Our Values</h1>

                        <ul>
                            <li className='font-serif text-[16px] pt-4'>Safety, competency, & development of our employees.</li>
                            <li className='font-serif text-[16px] pt-1'> Satisfaction of our customers through operational excellence. </li>
                            <li className='font-serif text-[16px] pt-1'>Effective use of advanced technology and information .</li>
                            <li className='font-serif text-[16px] pt-1'>Effective use of advanced technology and information  </li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className=''>
                <h1 className='text-center font-medium font-serif xs:text-xl md:text-4xl xs:pt-4 md:mt-20'> Why us</h1>
                <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
                <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4>
                <div className='grid xs:grid-cols-1 grid-cols-2 mt-10 m'>
                    <div className='col-span-1 ' >
                        <img src="newimage/why2.jpg" className='w-full sm:h-[550px]' alt="" />
                   
                    </div>
                    <div className='col-span-1 xs:py-20  bg-[#222]  '>
                        <div className='flex md:mx-20 xs:gap-x-4 md:mt-28'>
                            <div className='mx-auto '>
                                <img className='mx-auto pb-6' src='newimage/quality.svg'></img>
                                <h4 className='text-white text-center font-semibold font-sans'>WORLD CLASS QUALITY</h4>

                            </div>
                            <div className='mx-auto pt-[10px]'>
                                <img className='mx-auto pb-6' src='newimage/Reliability.svg'></img>
                                <h4 className='text-white text-center  font-semibold font-sans'>RELIABLE SERVICE EVER</h4>

                            </div>
                        </div>
                        <div className='flex xs:gap-x-4 xs:pt-10  md:mx-20 md:mt-28'>
                            <div className='mx-auto'>
                                <img className='mx-auto pb-6' src='newimage/safety.svg'></img>
                                <h4 className='text-white text-center mx-auto  font-semibold font-sans'>SAFETY</h4>
                            </div>
                            <div className='mx-auto'>
                                <img className='mx-auto pb-6' src='newimage/efficiancy.svg'></img>
                                <h4 className='text-white text-center mx-auto  font-semibold font-sans'>EFFICIENCY</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default About